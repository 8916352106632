@import '../../../theme.scss';

.vehicle-main-wrapper {
    width: 100%;
    height: auto;
    background: #000;
    padding: 5rem 0;
    overflow: hidden;

    .vehicle-wrapper {
        padding-left: calc((100% - 1280px) / 2);
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        gap: 2rem;


        .vehicle-left {
            flex: 0 0 628px;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;

            .vehicle-content-wrapper {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                gap: .75rem;

                .vehicle-title {
                    font-size: 2.25rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 122.222%;
                    letter-spacing: -0.72px;
                    color: $white;
                    @media(max-width:767px){
                        font-size: 1.5rem;
                    }
                }

                .vehicle-description {
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                    color: $white;
                }

            }

            .btn-wrap {
                width: 100%;
                height: auto;
                display: flex;
                gap: 1rem;
                .btn {
                    &:hover {
                        background: #000;
                        color: $white;
                    }
                }
            }

            @media(max-width:1199px) {
                flex: 0 0 auto;
                width: 100%;
            }
        }

        .vehicle-right {
            flex: 0 1 auto;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 1.5rem;
            margin-right: -4rem;

            @media(max-width:1199px) {
                max-width: 618px;
                width: 100%;
                margin: 0 auto;
            }
            @media(max-width:767px) {
                gap:.75rem; 
            }
        }

        @media(max-width:1330px) {
            padding-left: 2.5rem;
            padding-right: 2.5rem;
        }

        @media(max-width:1199px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 5rem;

        }
        @media(max-width:767px) {
            gap: 1.5rem;

        }
    }

    @media(max-width:1199px){
        padding: 2.5rem 0;
    }
}