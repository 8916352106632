@import '../../../theme.scss';

.choose-our-product-container {
    width: 100%;
    height: auto;
    background: $lightblue;
    position: relative;
    padding-bottom: 2rem;
    padding-top: 3.75rem;

    .container {
        .toggle-btn-wrapper.for_shop_plan {
            display: flex;
            align-items: center;
            gap: 24px;
            padding: 0;
            justify-content: center;
            @media (max-width:767px) {
                flex-wrap: wrap;
                flex-direction: column;                
            }
            span {
                height: 24px;
                display: inline-block;
                background: #B6B6B8;
                width: 1px;
                @media (max-width:767px) {
                        display: none;
                }
            }
            .switches-container {
                margin: 0;
                background: #fff;
                .switch{
                    background: #E5F0FF;
                }
                &.switchMoney {
                    max-width: 308px;
                    width: 308px;
                    position: relative;
                    
                    input:nth-of-type(2):checked~.switch-wrapper {
                        transform: translateX(100%);
                    }
    
                    input:nth-of-type(3):checked~.switch-wrapper {
                        transform: translateX(100%);
                    }
    
                    .switch-wrapper {
                        width: 33%;
                    }
    
                    input:nth-of-type(3):checked~.switch-wrapper .switch div:nth-of-type(3) {
                        opacity: 1;
                    }
                    input:nth-of-type(3):checked~.switch-wrapper {
                        transform: translateX(203%);
                    }
                }
    
            }
    
        }
        

        .toggle-btn-wrapper.for_shop_plan.bg-transparent{
            .switches-container {
                background: #B7D5FF;
                .switch{
                    background: #fff;
                }
            }
        }
        .choose-our-product-wrap {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            gap: 2.5rem;

            .choose-our-product {
                width: 343px;
                height: auto;
                display: flex;
                flex-direction: column;
                gap: 0.8rem;

                .form-label {
                    margin-bottom: 0;
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 133.333%;

                    @media(max-width:991px) {
                        font-size: 1.25rem;
                    }
                }

                @media(max-width:767px) {
                    width: 100%;
                    padding-right: 15px;
                }
            }

            .choose-product-wrap {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                gap: 24px;

                .choose-product-title {
                    font-size: 3rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 125%;
                    letter-spacing: -0.96px;
                    text-align: center;
                    @media(max-width:1199px) {
                        font-size: 2.25rem;
                    }

                    @media(max-width:991px) {
                        text-align: left;
                        font-size: 1.25rem;
                    }
                    @media(max-width:767px) {
                        text-align: center;
                    }
                }

                .product-slider {
                    width: 100%;

                    .product-box {
                        width: 100%;
                        border-radius: 1.125rem;
                        border: 1px solid #E7E7E7;
                        background: #FFF;
                        padding: 1rem;
                        display: flex;
                        flex-direction: column;
                        gap: 1rem;
                        height: 100%;

                        &.active {
                            border-color: $primary;
                        }

                        .choose-product-top {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            gap: .625rem;
                            align-items: center;

                            .product-tag {
                                border-radius: 4rem;
                                padding: .625rem 1.5rem;
                                font-size: 1rem;
                                font-weight: 600;
                                border: 1px solid #E7E7E7;
                                color: $primary;
                                line-height: 150%;
                            }

                            .product-select-wrap {
                                flex: 0 0 40px;

                                .custom-checkbox {
                                    width: 40px;
                                    height: 40px;
                                    position: relative;
                                    border-radius: 40px;
                                    background-color: $white;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    border: 1px solid #E7E7E7;
                                    cursor: pointer;

                                    svg {
                                        width: 16px;
                                        display: none;
                                    }
                                }

                                input[type="checkbox"] {
                                    visibility: hidden;
                                    opacity: 0;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    z-index: 0;


                                    &:checked+.custom-checkbox {
                                        width: 40px;
                                        height: 40px;
                                        position: relative;
                                        border-radius: 40px;
                                        background-color: $lightblue;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        border: 1px solid $lightblue;

                                        svg {
                                            width: 16px;
                                            display: block;
                                        }


                                    }
                                }


                            }
                        }

                        .choose-product-description {
                            width: 100%;
                            font-size: 1.125rem;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 155.556%;
                        }

                        .choose-product-footer {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            gap: .625rem;
                            align-items: center;
                            margin-top: auto;

                            .product-price {
                                flex: 0 0 auto;
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 150%;
                            }

                            .learn-more {

                                flex: 0 0 96px;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 155.556%;
                                text-decoration-line: underline;
                                color: $primary;
                                cursor: pointer;

                                &:hover {
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }
            }
        }

        .hr-line {
            width: 100%;
            height: 1px;
            background: #B6B6B8;
            margin-top: 2rem;
        }
    }

    @media(max-width:991px) {
        &::after {
            content: none;
        }
    }

    @media(max-width:767px) {
        .container {
            width: 100% !important;
            padding-left: 2.5rem !important;
        }
    }

    &.bg-transparent{
        background-color: transparent;
        
    }

}