.coverage-container-wrap {
    width: 100%;
    height: auto;
    padding: 5rem 0;

    .container {
        .coverage-content-wrapper {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            gap: 2rem;

            .coverage-section-title {
                width: 100%;
                height: auto;
                text-align: center;
                color: #0A0D12;
                font-size: 3rem;
                font-style: normal;
                font-weight: 700;
                line-height: 125%;
                letter-spacing: -0.96px;

                @media(max-width:1199px) {
                    font-size: 2.25rem;
                }

                @media(max-width:767px) {
                    font-size: 1.5rem;
                }
            }

            .cc-container {
                width: 100%;
                height: auto;
                border: 1px solid #E7E7E7;
                border-radius: 1.125rem;
                padding: 1.5rem;
                display: flex;
                gap: 1.5rem;

                &.service {


                    .cc-map-wraper {
                        flex: 1;
                        position: relative;
                        overflow: hidden;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        order: 0;
                        flex-direction: column;
                        gap: 24px;
                        @media(max-width:767px) {
                            gap: 16px
                        }

                        .coverage_map{
                            height: 450px;
                            border: 1px solid #E7E7E7;
                            overflow: hidden;                            
                            border-radius: 1rem;
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            @media(max-width:1100px) {
                                height: 350px;
                            }
                            @media(max-width:900px) {
                                height: 230px;
                            }
                        }
                        .coverage_detail {
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            @media(max-width:1100px) {
                                flex-wrap: wrap;
                                gap: 16px;
                            }
                            .info {
                                color: #0A0D12;
                                text-align: center;
                                font-size: 24px;
                                font-weight: 600;
                                line-height: 32px;
                                span {
                                    color: #848688;
                                    font-weight: 400;
                                }
                                @media(max-width:1100px) {
                                    width: calc(50% - 16px);
                                    text-align: left;
                                }
                                @media(max-width:767px) {
                                    font-size: 16px;
                                    line-height: 20px;
                                }
                            }
                           
                            
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                        svg{
                            width: 100% !important;
                            height: 100% !important;
                            object-fit: cover;
                            object-position: center; 
                        }

                        @media(max-width:991px) {
                        }
                        @media(max-width:767px) {                            
                            flex: unset;
                            height: 300px;
                            order: 1;
                        }
                        @media(max-width:420px) {   
                            height: 230px;
                        }
                    }

                    .cc-country-wrap {
                        flex: 0 1 270px;
                        display: flex;
                        flex-direction: column;
                        gap: 1rem;
                        order: 1;
                        @media(max-width:1100px) {
                            flex: 0 1 238px;
                            width: 238px;
                        }
                        @media(max-width:767px) {
                            width: 100%;
                        }

                        .cc-country-title {
                            font-size: 1.5rem;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 133.333%;
                            color: #0A0D12;

                            @media(max-width:767px) {
                                font-size: 1.25rem;
                            }
                        }

                        .country-list-wrap {
                            width: 100%;
                            flex: 1;
                            border: 1px solid #E7E7E7;
                            border-radius: 1rem;
                            padding: 1rem;
                            display: flex;
                            flex-direction: column;
                            gap: 1rem;

                            .country-list-title {
                                font-size: 1.125rem;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 155.556%;
                                color: #0A0D12;
                                width: 332px;

                                @media(max-width:1100px) {
                                    width: 100%;
                                }
                            }

                            .country-list {
                                width: 100%;
                                display: flex;
                                flex-wrap: wrap;
                                overflow: hidden;
                                flex: 1;
                                justify-content: flex-start;
                                gap: 1rem;
                                padding-right: 1rem;

                                .country-list-row {
                                    width: calc(50% - 0.5rem);
                                    display: flex;
                                    gap: .75rem;
                                    width: 100%;
                                    display: flex;
                                    gap: .75rem;

                                    .country-icon {
                                        flex: 0 0 24px;
                                        height: 24px;
                                        border-radius: 24px;
                                        border: 1px solid #E7E7E7;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;

                                        img {
                                            border-radius: 24px;
                                            width: 100%;
                                            height: 100%;
                                            object-fit: cover;
                                            object-position: center;
                                        }
                                    }

                                    .country-name {
                                        flex: 1;
                                        font-size: 1rem;
                                        color: #0A0D12;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 150%;
                                    }

                                    @media(max-width:1199px) {
                                        width: 100%;
                                    }

                                }

                            }

                        }

                        @media(max-width:991px) {
                            gap: 0.5rem;
                            flex: unset;
                            height: 379px;
                            order: 0;
                        }
                    }

                    @media(max-width:767px) {
                        flex-direction: column;
                        padding: 1rem;
                    }
                }

            }

        }
    }

    @media(max-width:991px) {
        padding:2.5rem 0;
    }

    @media(max-width:767px) {
        padding:2rem 0;
    }
}