@import '../../../theme.scss';

.services-wrapper {
    width: 100%;
    height: auto;
    padding: 7.75rem 0 5rem;
    background: #000;

    .service-content-wrapper {
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2.5rem;

        .service-col {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;

            .badge-wrapper {
                width: 100%;
                height: auto;
                display: flex;
                justify-content: center;

                .badge {
                    display: flex;
                    padding: 8px 12px;
                    flex-direction: column;
                    align-items: center;
                    gap: 8px;
                    border-radius: 4rem;
                    background-color: #3B3D41;
                }
            }

            .service-title {
                font-size: 3rem;
                color: $white;
                font-style: normal;
                font-weight: 700;
                line-height: 125%;
                letter-spacing: -0.96px;
                text-align: center;

                @media(max-width:767px) {
                    font-size: 2.25rem;
                }
            }

            .service-list-wrapper {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                gap: 1.5rem;

                .service-list-row {
                    width: 100%;
                    height: auto;
                    display: flex;
                    align-items: center;
                    gap: .75rem;

                    .shield-icon {
                        flex: 0 0 40px;
                        display: flex;
                        justify-content: center;

                        @media(max-width:767px) {
                            flex: 0 0 32px;

                            svg {
                                width: 21px;
                            }
                        }
                    }

                    .service-content {
                        flex: 1;
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 150%;
                        color: $white;
                    }
                }
            }

            .divider {
                width: 100%;
                height: 1px;
                background: #3B3D41;
            }

            .additional-info-wrap {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                gap: .375rem;

                .additional-info-title {
                    width: 100%;
                    height: auto;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 150%;
                    color: $white;
                }

                .additional-info-description {
                    font-size: .875rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 142.857%;
                    color: $white;
                }

                .additional-info-list {
                    margin: 0;
                    padding: 0;
                    list-style-type: none;
                    display: flex;
                    flex-direction: column;

                    li {
                        width: 100%;
                        font-size: .875rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 142.857%;
                        color: $white;
                        padding-left: 1.25rem;
                        position: relative;

                        &::before {
                            content: '';
                            width: 2px;
                            height: 2px;
                            border-radius: 100%;
                            background: $white;
                            position: absolute;
                            top: 10px;
                            left: 10px;
                            z-index: 1;
                        }
                    }
                }
            }

            .enquire {
                width: 100%;
                height: auto;
                display: flex;
                justify-content: center;
            }
        }

        @media(max-width:1199px) {
            gap: 1.5rem;

        }

        @media(max-width:767px) {
            grid-template-columns: repeat(1, 1fr);
            gap: 2.5rem;
        }
    }

    @media(max-width:1199px) {
        padding: 5.3125rem 0 2.5rem;
    }

    @media(max-width:767px) {
        padding: 3.75rem 0 2.5rem;
    }
}