$form-bg-color:#ffffff;
$body-color:#0A0D12;
$bgGray:#F7F7F8;
$white: #fff !default;
$danger:#FF4B4B;
$success:#6DB143;
$warning:#FBD54D;
$primary:#0068FF;
$lightblue:#E5F0FF;

$secondary:#E7E7E7;
$btn-border-radius: 4rem;
$btn-padding-y:.75rem !important;
$btn-padding-x:1.5rem;
$btn-font-size:1rem !important;
$btn-font-family:'Urbanist', sans-serif;
$btn-font-weight:700;
$input-bg:$form-bg-color;
$input-border-radius:5.625rem;
$btn-line-height:1.375rem;
$input-padding-y:1rem;
$input-padding-x: 1.5rem;
$input-border-color:#E7E7E7;
$input-group-addon-bg: transparent;
$form-check-input-bg:$input-bg;
$form-select-border-radius:5.625rem !important;
$form-select-indicator:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='10' viewBox='0 0 17 10' fill='none'%3E%3Cpath d='M15.4098 1.5L8.40979 8.5L1.40979 1.5' stroke='%23404041' strokeWidth='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
$form-select-padding-y-sm:0.507rem;
$form-label-font-size:1.125rem;
$form-label-font-weight:600;
$form-label-color: #0A0D12;



// $card-border-radius: .625rem;
// $card-border-color: transparent !important;
// $modal-content-border-color:transparent;
// $modal-header-border-color: transparent;
// $modal-footer-border-color:transparent;
// $modal-header-padding:1.375rem 1.5625rem 0;
// $modal-content-border-radius:.5625rem;
// $modal-inner-padding:1rem 1.5625rem 1.375rem 1.5625rem;
// $modal-footer-padding:1.5625rem;
$font-family-sans-serif:'Urbanist', sans-serif !important;



// $modal-sm: 537px !default;
// $modal-lg:998px !default;
// $dropdown-border-color:transparent ;
// $dropdown-border-radius:6px;
// $dropdown-padding-y:1.1875rem;
// $dropdown-padding-x:1.1875rem;

@import 'node_modules/bootstrap/scss/bootstrap.scss';