@import '../../../theme.scss';

.our-products-wrapper {
    width: 100%;
    height: auto;
    padding: 5rem 0 0;
    position: relative;
   
    &:after{
        content: "";
        width: 100%;
        height: 467px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background: #E5F0FF

    }
    .container {
        position: relative;
        z-index: 2;


        .product-title-wrap {
            display: flex;
            justify-content: center;
            font-size: 3rem;
            font-style: normal;
            font-weight: 700;
            line-height: 125%;
            letter-spacing: -0.96px;
            color: #0A0D12;
            width: 100%;
            margin-bottom: 20px;

            @media(max-width:1199px) {
                font-size: 2.25rem;
            }

            @media(max-width:767px) {
                font-size: 1.5rem;
            }
        }

        .product-box-wrapper {
            width: 100%;
            display: flex;
            justify-content: center;
            gap: 1.5rem;
            flex-wrap: wrap;
            margin-top: 2rem;

            .product-box {
                width: calc(33.333333% - 1.5rem);
                border: 1px solid #E7E7E7;
                padding: 1rem;
                border-radius: 1.125rem;
                background: $white;
                display: flex;
                flex-direction: column;
                gap: 1.5rem;

                &.active {
                    border-color: #0068FF;
                }

                .choose-product-top {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    gap: 0.625rem;
                    align-items: center;
                    .product-select-wrap {
                        flex: 0 0 40px;
                        position: relative;
                        input[type=checkbox] {
                            visibility: hidden;
                            opacity: 0;
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 0;
                            &:checked + .custom-checkbox {
                                width: 40px;
                                height: 40px;
                                position: relative;
                                border-radius: 40px;
                                background-color: #E5F0FF;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border: 1px solid #E5F0FF;
                                svg{
                                    display: block;
                                }
                            }
                        }
                        .custom-checkbox {
                            width: 40px;
                            height: 40px;
                            position: relative;
                            border-radius: 40px;
                            background-color: #fff;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border: 1px solid #E7E7E7;
                            cursor: pointer;
                            svg{
                                display: none;
                            }
                        }
        
                    }
                }
                
                

                .product-tag {
                    width: 100%;

                    span {
                        border-radius: 4rem;
                        padding: .625rem 1.5rem;
                        font-size: 1rem;
                        font-weight: 600;
                        border: 1px solid #E7E7E7;
                        color: $primary;
                        display: inline-block;
                        line-height: 150%;
                    }
                }

                .product-img-wrap {
                    width: 100%;
                    height: 217px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        object-fit: cover;
                        object-position: center;
                    }
                }

                .product-detail-wrap {
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;

                    .product-detail-title-wrap {
                        width: 100%;
                        height: auto;
                        display: flex;
                        flex-direction: column;

                        .product-detail-title {
                            font-size: 1.5rem;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 133.333%;
                            letter-spacing: -0.96px;
                        }

                        .product-detail-subtitle {
                            font-size: 1rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 150%;
                            color: #6C6E71;
                        }
                    }

                    .our-products-wrap {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: .25rem;

                        .our-products-row {
                            width: 100%;
                            display: flex;
                            gap: .5rem;

                            .product-icon-wrap {
                                flex: 0 0 8px;
                            }

                            .our-products {
                                flex: 1;
                                font-size: 1rem;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 150%;
                            }
                        }
                    }
                }

                .learn-more {
                    margin-top: auto;
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;

                    span {
                        color: $primary;
                        font-size: 1rem;
                        text-decoration: underline;
                        cursor: pointer;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }

                @media(max-width:991px) {
                    width: calc(50% - 1.5rem);
                }

                @media(max-width:767px) {
                    width: 100%;
                }
            }
        }

        @media(max-width:991px) {
            padding: 4rem 0;
        }
    }

    @media(max-width:767px) {
        padding: 2.5rem 0;
    }
}


.choose-product-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 0.625rem;
    align-items: center;
    .product-select-wrap {
        flex: 0 0 40px;
        position: relative;
        input[type=checkbox] {
            visibility: hidden;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            &:checked + .custom-checkbox {
                width: 40px;
                height: 40px;
                position: relative;
                border-radius: 40px;
                background-color: #E5F0FF;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #E5F0FF;
                svg{
                    display: block;
                }
            }
        }
        .custom-checkbox {
            width: 40px;
            height: 40px;
            position: relative;
            border-radius: 40px;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #E7E7E7;
            cursor: pointer;
            svg{
                display: none;
            }
        }

    }
}


.product-box.active {
        border-color: #0068FF !important;
}


.toggle-btn-wrapper.for_shop_plan {
    display: flex;
    align-items: center;
    gap: 24px;
    padding: 0;
    justify-content: center;
    @media (max-width:767px) {
        flex-wrap: wrap;
        flex-direction: column;                
    }
    span {
        height: 24px;
        display: inline-block;
        background: #B6B6B8;
        width: 1px;
        @media (max-width:767px) {
                display: none;
        }
    }
    .switches-container {
        margin: 0;
        background: #fff;
        .switch{
            background: #E5F0FF;
        }
        &.switchMoney {
            max-width: 308px;
            width: 308px;
            position: relative;
            
            input:nth-of-type(2):checked~.switch-wrapper {
                transform: translateX(100%);
            }

            input:nth-of-type(3):checked~.switch-wrapper {
                transform: translateX(100%);
            }

            .switch-wrapper {
                width: 33%;
            }

            input:nth-of-type(3):checked~.switch-wrapper .switch div:nth-of-type(3) {
                opacity: 1;
            }
            input:nth-of-type(3):checked~.switch-wrapper {
                transform: translateX(203%);
            }
        }

    }

}


.toggle-btn-wrapper.for_shop_plan.bg-transparent{
    .switches-container {
        background: #B7D5FF;
        .switch{
            background: #fff;
        }
    }
}