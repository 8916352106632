@import '../../../theme.scss';

.ourapp-banner-wrapper {
    width: 100%;
    height: auto;

    .ourapp-banner {
        padding-left: calc((100% - 1280px) / 2);
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        gap: 2rem;
        padding-top: 6.5rem;

        .ourapp-content-wrap {
            flex: 0 0 589px;
            display: flex;
            flex-direction: column;
            gap: 2.5rem;


            .ourapp-title-wrapper {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                gap: 1rem;

                h1 {
                    font-size: 3.75rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    letter-spacing: -1.2px;
                    margin: 0;
                    padding: 0;

                    @media(max-width:1199px) {
                        max-width: 714px;
                        width: 100%;
                        font-size: 3rem;
                    }

                    @media(max-width:767px) {
                        max-width: 343px;
                        width: 100%;
                        font-size: 1.5rem;
                    }
                }

                p {
                    margin: 0;
                    padding: 0;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                    max-width: 411px;
                    width: 100%;
                }
            }

            .view-product-wrap {
                width: 100%;
            }

            @media(max-width:1199px) {
                flex: 1;
                width: 100%;
            }
            .app-icon-container{
                width: 100%;
                height: auto;
                display: flex;
                align-items: center;
                gap:1rem;
                @media(max-width:1199px){
                    .app-icon{
                        img{
                            height: 48px;
                        }
                    }
                }
            }
        }

        .handheld-iPhone {
            flex: 0 1 auto;
            display: flex;
            justify-content: flex-end;

            @media(max-width:1199px) {
               
                    max-width: 411px;
                    width: 100%;
                    margin-top: -5.9375rem;
                    align-self: flex-end ;
                   
                }

                @media(max-width:767px) {
                    max-width: 245px;
                    width: 100%;
                    margin-top: 3.25rem;
                    align-self: flex-end;
                }
            
        }

        @media(max-width:1330px) {
            padding-left: 40px;
        }

        @media(max-width:1199px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 0;

        }

        @media(max-width:767px) {
            padding-top: 1.25rem;
        }
    }
}