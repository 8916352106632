@import '../../../theme.scss';

.login-form-wrap {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-bottom: 3rem;

    .login-input-container {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .form-label {
            width: 100%;
            margin-bottom: 0.6rem;
            font-size: 1.125rem;
        }
    }

    .remember-wrap {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;

        .custom-checkbox {
            flex: 0 0 auto;
        }

        .forgot-wrap {
            flex: 0 0 auto;
            cursor: pointer;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 500;
            line-height: 155.556%;
            text-decoration-line: underline;
            color: $primary;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .btn-wrap {
        width: 100%;
        height: auto;

        .btn {
            width: 100%;
        }
    }

    .have-an-account-wrapper {
        width: 100%;
        height: auto;
        text-align: center;
        font-size: 1rem;
        span {
            color: $primary;
            text-decoration: underline;
            cursor: pointer;
            &:hover {
                text-decoration: none;
            }
        }
    }
}