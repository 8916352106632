@import '../../../theme.scss';

.ready-transform-wrapper {
    width: 100%;
    height: auto;
    padding: 5rem 0;
    background: #0068FF;

    .rt-content-wrap {
        max-width: 980px;
        width: calc(100% - 80px);
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 2.5rem;

        .rt-heading-wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .rt-heading {
                font-size: 4.5rem;
                font-style: normal;
                font-weight: 600;
                line-height: 125%;
                letter-spacing: -1.44px;
                color: $white;
                text-align: center;
                @media(max-width:1199px){
                    font-size: 3rem;
                }
                @media(max-width:767px){
                    font-size:1.5rem;
                }
            }

            .rt-description {
                max-width: 622px;
                width: 90%;
                margin: 0 auto;
                font-size: 1.125rem;
                color: $white;
                font-style: normal;
                font-weight: 400;
                line-height: 155.556%;
                text-align: center;
            }
        }

        .rt-order-wrap {
            width: 100%;
            display: flex;
            justify-content: center;

            .btn {
                color: $primary;

                &:hover {
                    background: $primary;
                    color: $white;
                }
            }
        }

        .rt-link-wrap {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap:0.5rem;
            color: $white;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 400;
            line-height:133.336%;
            justify-content: center;
            text-align: center;

            .rt-link {
                span{
                    text-decoration: underline;
                    cursor: pointer;
                    &:hover{
                        text-decoration: none;
                    }
                }
            }
        }

    }

    @media(max-width: 991px){
        padding: 2.5rem 0;
    }
    @media(max-width: 767px){
        padding: 2rem 0;
    }
}